hr { 
	display: block; 
	margin: 1em 0; 
	padding: 0;
	height: 1px; 
	border: 0; 
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #fff; 
}
/* Figures and images ==================================== */
figure {
	margin-bottom: $indent-var;
}
article img {
	max-width: 100%;
	height: auto;
	border-width: 0; 
	vertical-align: middle; 
	-ms-interpolation-mode: bicubic; 
}
svg:not(:root) {
		overflow: hidden; 
}
.half {
	@include container;
	@include clearfix;
	@media #{$micro} {
		img {
			@include grid(12,6);
		}
		figcaption {
			@include grid(12,12);
		}
	}
}
.third {
	@include container;
	@include clearfix;
	@media #{$micro} {
		img {
			@include grid(12,4);
		}
		figcaption {
			@include grid(12,12);
		}
	}
}
/* Buttons ============================================== */
.btn {
  display: inline-block;
  margin-bottom: 20px;
  padding: 8px 20px;
  @include font-rem(14);
  font-family: $heading-font;
  font-weight: 700;
  background-color: $primary;
  color: $white;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: $primary;
  text-decoration: none !important;
  @include rounded(3px);
  &:visited {
    color: $white;
  }
  &:hover {
    background-color: $white;
    color: $primary;
  }
}
.btn-success {
  background-color: $success;
  color: $white;
  border-color: $success;
  &:visited {
    color: $white;
  }
  &:hover {
    background-color: $white;
    color: $success;
  }
}
.btn-warning {
  background-color: $warning;
  color: $white;
  border-color: $warning;
  &:visited {
    color: $white;
  }
  &:hover {
    background-color: $white;
    color: $warning;
  }
}
.btn-danger {
  background-color: $danger;
  color: $white;
  border-color: $danger;
  &:visited {
    color: $white;
  }
  &:hover {
    background-color: $white;
    color: $danger;
  }
}
.btn-info {
  background-color: $info;
  color: $white;
  border-color: $info;
  &:visited {
    color: $white;
  }
  &:hover {
    background-color: $white;
    color: $info;
  }
}
/* Well ================================================= */
.well {
	min-height: 20px;
	padding: 19px;
	margin-bottom: 20px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	@include rounded(4px);
	@include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.05));
}